import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../layout/Layout'
import Seo from '../../components/Seo'
import { Portfolio } from '../../sections/index'

import hero from '../../images/banners/allency-banner-briefcase.png'
import SmallHeroBand from '../../sections/SmallHeroBand'
import { SmallBand } from '../../sections/BandSection'

const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      portfolioOne: file(relativePath: { eq: "stock/allency-briefcase-run-santa-run.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioTwo: file(relativePath: { eq: "stock/allency-briefcase-dental-doctors.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioThree: file(relativePath: { eq: "stock/allency-briefcase-gigi-snacks.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioFour: file(relativePath: { eq: "stock/allency-briefcase-food-truck-fest.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioFive: file(relativePath: { eq: "stock/allency-briefcase-burger-fest.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioSix: file(relativePath: { eq: "stock/allency-briefcase-felah-room.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

    }
  `)

  const portfolioOne = dataImage.portfolioOne.childImageSharp.gatsbyImageData
  const portfolioTwo = dataImage.portfolioTwo.childImageSharp.gatsbyImageData
  const portfolioThree = dataImage.portfolioThree.childImageSharp.gatsbyImageData
  const portfolioFour = dataImage.portfolioFour.childImageSharp.gatsbyImageData
  const portfolioFive = dataImage.portfolioFive.childImageSharp.gatsbyImageData
  const portfolioSix = dataImage.portfolioSix.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Portafolio',
    },
    portfolio: {
      items: [
        {
          image: portfolioOne,
          title: 'Run Santa Run',
          services: 'Publicidad | Desarrollo web | Mkt digital',
          button: 'Conoce más',
          link: '/portfolio/run-santa-run',
        },
        {
          image: portfolioTwo,
          title: 'Dental Doctors',
          services: 'Mkt digital',
          button: 'Conoce más',
          link: '/portfolio/dental-doctors',
        },
        {
          image: portfolioThree,
          title: 'Gigi Snacks',
          services: 'Branding',
          button: 'Conoce más',
          link: '/portfolio/gigi-snacks',
        },
        {
          image: portfolioFour,
          title: 'Tecate Food Truck Fest',
          services: 'Publicidad | Mkt digital',
          button: 'Conoce más',
          link: '/portfolio/food-truck-fest',
        },
        {
          image: portfolioFive,
          title: 'Burger Fest',
          services: 'Mkt digital',
          button: 'Conoce más',
          link: '/portfolio/burger-fest',
        },
        {
          image: portfolioSix,
          title: 'Felah Room',
          services: 'Branding | Mkt digital',
          button: 'Conoce más',
          link: '/portfolio/felah-room',
        },
      ]
    }
  }
  

  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Conoce nuestro portafolio'}
        description={"Nuestra manera de trabajar tiene impresa una marca de calidad que podrás encontrar en nuestros proyectos de branding, publicidad, desarrollo web y marketing digital."}
        keywords={['Publicidad,Estrategia,Soluciones,Agencia,Resultados,Portafolio,Marcas,Calidad,Proyecto,Marketing,Diseño,Programación,Posicionamiento,Branding']}
        noIndex={''}
        noFollow={''}
        slug={'portfolio'}
        canonicalUrl={'https://allency.com.mx/portfolio'}
        ogTitle={'Conoce nuestro portafolio'}
        ogType={'website'}
        ogDescription={"Nuestra manera de trabajar tiene impresa una marca de calidad que podrás encontrar en nuestros proyectos de branding, publicidad, desarrollo web y marketing digital."}
        ogImage={'../../images/logos/allency-dark.png'}
      />

      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Portfolio contents={contents.portfolio} />
      <SmallBand />
    </Layout>
  )
}

export default IndexPage